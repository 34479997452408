import { Avatar, Flex, Typography } from 'antd/lib';
import Sider from 'antd/lib/layout/Sider';
import Menu from 'antd/lib/menu';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useMemo } from 'react';
import { AiOutlineCodeSandbox, AiOutlineStock } from 'react-icons/ai';
import { BsCalendarCheck } from 'react-icons/bs';
import { FiHome } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import MobileCarCareLogoSmall from '../assests/images/icon/carWashIcon.png';

interface NavbarProps {
  collapsed: boolean;
}

export default function Navbar({ collapsed = false }: NavbarProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = useMemo(
    () =>
      [
        {
          title: 'Dashboard',
          label: 'Dashboard',
          icon: <FiHome />,
          key: '/dashboard',
        },
        {
          title: 'Providers',
          label: 'Providers',
          icon: <AiOutlineCodeSandbox />,
          key: '/providers',
        },
        {
          title: 'Bookings',
          label: 'Bookings',
          icon: <BsCalendarCheck />,
          key: '/bookings',
        },
        {
          title: 'Seekers',
          label: 'Seekers',
          icon: <AiOutlineStock />,
          key: '/seekers',
        },
        {
          name: 'Withdrawals',
          label: 'Withdrawals',
          icon: <FiHome />,
          key: '/withdrawals',
        },
        // {
        //   name: 'Settings',
        //   icon: <FiSettings />,
        //   path: '/setting',
        // },
      ] as ItemType[],
    []
  );

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
      }}
      theme='light'
    >
      <Flex style={{ height: '100vh' }} vertical gap='middle'>
        <Flex
          style={{ height: 64 }}
          align='center'
          justify='center'
          gap='small'
        >
          <Avatar
            size='large'
            shape='square'
            src={MobileCarCareLogoSmall}
            alt='mcc'
          >
            MCC
          </Avatar>
          {!collapsed && (
            <Flex vertical>
              <Typography.Title
                level={5}
                style={{ margin: 0, lineHeight: 1.2 }}
              >
                Mobile Car Care
              </Typography.Title>
              <Typography.Text
                type='secondary'
                style={{ lineHeight: 1.1, fontSize: 'small' }}
              >
                mcc@gmail.com
              </Typography.Text>
            </Flex>
          )}
        </Flex>
        <Menu
          mode='vertical'
          defaultSelectedKeys={[location.pathname || '/dashboard']}
          items={menuItems}
          onClick={(e) => handleNavigate(e.key)}
          title='asdasd'
        />
      </Flex>
    </Sider>
  );
}
