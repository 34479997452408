import { QueryClient } from '@tanstack/react-query';
import type { IServiceRequestResponse } from '../Components/Booking/booking.interface';
import apiRoutes from './api.routes';
import api from './api.service';

const SERVICE_REQUEST_LIST = (params: IPaginationParams) => [
  'service-requests',
  'list',
  [params],
];

export const fetchServiceRequests = (
  params: IPaginationParams = {
    page: 1,
    limit: 10,
    sortKey: 'createdAt',
    sortType: 'desc',
  }
) => ({
  queryKey: SERVICE_REQUEST_LIST(params),
  queryFn: async () =>
    await api
      .get<IServiceRequestResponse>(apiRoutes.serviceRequests.getList, {
        params,
      })
      .then(({ data }) => data),
  retryOnMount: true,
});

export const serviceRequesterPageLoader = async (queryClient: QueryClient) => {
  const query = fetchServiceRequests();
  return (
    queryClient?.getQueryData<IServiceRequestResponse>(query.queryKey) ??
    (await queryClient?.fetchQuery<IServiceRequestResponse>(query))
  );
};
