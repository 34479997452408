import axios from 'axios';

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
axios.defaults.headers.common['Access-Control-Allow-Headers'] =
  'Content-Type, Authorization';
axios.defaults.headers.common['Access-Control-Allow-Methods'] =
  'GET, POST, PUT, DELETE, OPTIONS, PATCH';
axios.defaults.headers.common['Access-Control-Allow-Origin'] =
  process.env.REACT_APP_API_URL ?? '*';
// window.location.origin;
axios.defaults.headers.common['Content-Type'] = 'application/json';
// axios.defaults.withCredentials = true;

/**
 * Request interceptor
 * Runs every time before a request is hit
 * Checks for cookie token for ever request
 */
axios.interceptors.request.use(
  (config) => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    };
    return config;
  },
  (error) => Promise.reject(error)
);

/**
 * Response interceptor
 * Runs every time after a response is received
 * handles error
 */
axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (error?.request?.status)
      await errorHandler(error.request.status!, error?.config);

    return Promise.reject(error);
  }
);

async function errorHandler(status: number, config: any) {
  const originalRequest = { ...config };
  switch (status) {
    case 500:
      break;
    case 401: {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.href = '/login';
      // const retry = originalRequest._retry;
      // if (!retry) {
      //   originalRequest._retry = true;
      //   const response = await axios.get(
      //     `${process.env.REACT_APP_TOKEN_URL}/api/auth/refresh`,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
      //       },
      //     }
      //   );
      //   // // .then((response) => {
      //   const { accessToken, refreshToken } = response.data;
      //   localStorage.setItem('accessToken', accessToken);
      //   localStorage.setItem('refreshToken', refreshToken);
      //   // })
      //   // .catch((error) => {
      //   //   console.log(
      //   //     '🚀 ~ file: api.header.ts:68 ~ errorHandler ~ error:',
      //   //     error
      //   //   );
      //   //   if (error.response.data.statusCode === 401) {
      //   //     localStorage.removeItem('accessToken');
      //   //     localStorage.removeItem('refreshToken');
      //   //     window.location.href = '/login';
      //   //   }
      //   // });
      // } else {
      //   console.log('HERE');

      // }
      return originalRequest;
    }
    default:
      break;
  }
  return null;
}
// function axiosApiInstance(config: any) {
//   throw new Error('Function not implemented.');
// }
