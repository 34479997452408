import axios from 'axios';
import { API_URL, TOKEN_URL } from './constants';
import { toast } from 'react-toastify';

axios.defaults.baseURL = API_URL;
const axiosApiInstance = axios.create();

export const setTokenOnAxios = (token?: string) => {
  !!token &&
    (axios.defaults.headers.common['Authorization'] = `Bearer ${token}`);
};

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    console.log('<<<+++bbbb>>>>', error.response.status);

    if (error.response.status === 401 && !originalRequest._retry) {
      console.log('here>>>>>');
      originalRequest._retry = true;

      try {
        console.log('<<<<<try', localStorage.getItem('refreshToken'));
        const rs = await axios.get(`${TOKEN_URL}api/auth/refresh`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
          },
        });
        console.log('<<<<now>>>', rs);

        const { accessToken, refreshToken } = rs.data;

        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${accessToken}`;

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);

        return axiosApiInstance(originalRequest);
      } catch (_error) {
        if (error.response.data.statusCode === 401) {
          toast.info(`YOUR SESSION IS EXPIRED \n Please login again`);
          toast.clearWaitingQueue();
          window.location.href = `login`;
          localStorage.clear();
        }

        return Promise.reject(_error);
      }
    }
    return Promise.reject(error);
  }
);

export const apis = {
  getMe: () => axiosApiInstance.get(API_URL + '/me').then(({ data }) => data),
  getWashRequest: (
    page: number,
    limit: number,
    sortKey: string,
    sortType: string
  ) => {
    return axiosApiInstance.get(
      `/service-requests?page=${page}&limit=${limit}&sortKey=${sortKey}&sortType=${sortType}`
    );
  },
  getProviderData: (
    page: number,
    limit: number,
    sortKey: string,
    sortType: string
  ) => {
    return axiosApiInstance.get(
      `/providers?page=${page}&limit=${limit}&sortKey=${sortKey}&sortType=${sortType}`
    );
  },
  getTotalCount: () => {
    return axiosApiInstance.get(API_URL + '/dashboard');
  },
  getGraphData: () => {
    return axiosApiInstance.get(API_URL + '/graph');
  },
  getProviderInformation: (providerID: any) => {
    return axiosApiInstance.get(`/providers/${providerID}`);
  },
  getServiceStationInformation: (businessID: any) => {
    return axiosApiInstance.get(`/serviceStations?businessId=${businessID}`);
  },
  getRevenueAndEarnings: (businessID: any) => {
    return axiosApiInstance.get(`/totalEarnings?businessId=${businessID}`);
  },
  adminLogin: (userdata: any) => {
    return axios.post(`/webLogin`, userdata);
  },
  updateProviderVerificationStatus: (providerId: any, verify: boolean) => {
    return axiosApiInstance.post(
      `/providers/${providerId}/verify?verified=${verify}`
    );
  },
  getServiceStationTypeData: (serviceId: any, serviceStationId: any) => {
    return axiosApiInstance.get(
      `${TOKEN_URL}api/provider-jobs/service/${serviceId}/serviceStation/${serviceStationId}`
    );
  },
  getSearchResult: (searchString: any, searchType: any) => {
    return axiosApiInstance.get(
      `/search?queryString=${searchString}&queryType=${searchType} `
    );
  },
};
