import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { setTokenOnAxios } from '../apis/apiServices';
import { routes } from '../Routes/App.rotues';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10000,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
    },
  },
});
const appRoutes = routes(queryClient);

const App = () => {
  const token = localStorage.getItem('accessToken') ?? undefined;
  useEffect(() => {
    setTokenOnAxios(token);
  }, [token]);
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={appRoutes} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>

      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
        closeButton={<></>}
        limit={1}
      />
    </>
  );
};

export default App;
