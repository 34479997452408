/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState } from 'react';
import './style.scss';
import Navbar from '../Components/Navbar/Navbar';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Button } from 'antd/lib';
import Header from '../Components/Header/Header';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

interface propsType {
  children: React.ReactNode;
}
const ProtectedLayouts = ({ children }: propsType) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Navbar collapsed={collapsed} />
      <Layout style={{ marginLeft: collapsed ? 80 : 200 }}>
        <Header
          navBarCollapseAction={() => (
            <Button
              type='text'
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />
          )}
        />
        <Content style={{ margin: '24px 24px 0', overflow: 'initial' }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default memo(ProtectedLayouts);
