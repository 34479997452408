import type { QueryClient } from '@tanstack/react-query';
import type { IProviderResponse } from '../Components/Provider/provider.interface';
import apiRoutes from './api.routes';
import api from './api.service';

// QUERY KEYS
const PROVIDER_LIST = (params: IPaginationParams) => [
  'provider',
  'list',
  [params],
];

export const fetchProviders = (
  params: IPaginationParams = {
    page: 1,
    limit: 10,
    sortKey: 'createdAt',
    sortType: 'desc',
  }
) => ({
  queryKey: PROVIDER_LIST(params),
  queryFn: async () =>
    await api
      .get<IProviderResponse>(apiRoutes.provider.getList, {
        params,
      })
      .then(({ data }) => data),
  retryOnMount: true,
});

export const providerPageLoader = async (queryClient: QueryClient) => {
  const query = fetchProviders();
  return (
    queryClient?.getQueryData<IProviderResponse>(query.queryKey) ??
    (await queryClient?.fetchQuery<IProviderResponse>(query))
  );
};
