import { QueryClient } from '@tanstack/react-query';
import apiRoutes from './api.routes';
import api from './api.service';
import { IPaginatedWithdrawal } from '../Components/Withdraws/withdrawal.interface';

// QUERY KEYS
const WITHDRAWAL_LIST = (params: IPaginationParams) => [
  'withdrawl',
  'list',
  [params],
];

export const fetchWithdrawals = (
  params: IPaginationParams = {
    page: 1,
    limit: 5,
    sortKey: 'createdAt',
    sortType: 'desc',
  }
) => ({
  queryKey: WITHDRAWAL_LIST(params),
  queryFn: async () =>
    await api
      .get<IPaginatedWithdrawal>(apiRoutes.withdrawal.getList, {
        params,
      })
      .then(({ data }) => data),
  retryOnMount: true,
});

export const withDrawalsPageLoader = async (queryClient: QueryClient) => {
  const query = fetchWithdrawals();
  return (
    queryClient?.getQueryData<IPaginatedWithdrawal>(query.queryKey) ??
    (await queryClient?.fetchQuery<IPaginatedWithdrawal>(query))
  );
};
