import { Avatar, Flex } from 'antd/lib';
import './loader.scss';
import MobileCarCareLogoSmall from '../assests/images/icon/carWashIcon.png';

export default function Loader() {
  return (
    <Flex vertical align='center' justify='center' gap='middle'>
      <Avatar
        size='large'
        shape='square'
        src={MobileCarCareLogoSmall}
        alt='mcc'
        style={{ filter: 'grayscale(1)', position: 'absolute' }}
      />
      <span className='loader'></span>
    </Flex>
  );
}
