import { QueryClient } from '@tanstack/react-query';
import { ISeekerRequestResponse } from '../Components/Seeker/seeker.interface';
import apiRoutes from './api.routes';
import api from './api.service';

// QUERY KEYS
const SEEKER_LIST = (params: IPaginationParams) => ['seeker', 'list', [params]];

export const fetchSeekers = (
  params: IPaginationParams = {
    page: 1,
    limit: 10,
    sortKey: 'createdAt',
    sortType: 'desc',
  }
) => ({
  queryKey: SEEKER_LIST(params),
  queryFn: async () =>
    await api
      .get<ISeekerRequestResponse>(apiRoutes.seeker.getList, {
        params,
      })
      .then(({ data }) => data),
  retryOnMount: true,
});

export const seekerPageLoader = async (queryClient: QueryClient) => {
  const query = fetchSeekers();
  return (
    queryClient?.getQueryData<ISeekerRequestResponse>(query.queryKey) ??
    (await queryClient?.fetchQuery<ISeekerRequestResponse>(query))
  );
};
