import { MenuProps, Row, Avatar, theme, Flex, Space, Dropdown } from 'antd';
import { useLocation, useMatches, useNavigate } from 'react-router-dom';
import { Header as LayoutHeader } from 'antd/lib/layout/layout';
import { Typography } from 'antd/lib';

interface HeaderProps {
  navBarCollapseAction: () => React.ReactNode;
}
export default function Header({ navBarCollapseAction }: HeaderProps) {
  const navigate = useNavigate();

  const location = useLocation();
  const route = useMatches()?.find((m) => m.pathname === location.pathname);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Row align='middle'>
          <div className='' style={{ height: 50, width: 50 }}>
            <Avatar
              className='image car-wash-admin-dropdown-image'
              src='https://images.pexels.com/photos/13195312/pexels-photo-13195312.jpeg?auto=compress&cs=tinysrgb&w=1260&h=1260&dpr=1'
              alt='profile'
              size='small'
              shape='circle'
            />
          </div>
          <div
            className='car-wash-admin-name'
            style={{ position: 'relative', left: '10px' }}
          >
            <h1
              style={{
                fontFamily: 'Helvetica',
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '17px',
              }}
            >
              {' '}
              Ramen Naruto
            </h1>
            <h4
              style={{
                fontFamily: 'Helvetica',
                fontWeight: '400',
                fontSize: '12px',
                lineHeight: '13px',
              }}
            >
              ramencarcare@gmail.com
            </h4>
          </div>
        </Row>
      ),
    },
    {
      key: '2',
      label: <div>My Profile</div>,
    },
    {
      key: '3',
      label: <div>Inbox</div>,
    },
    {
      key: '3',
      label: (
        <div
          onClick={(e) => {
            e.preventDefault();
            AdminLogout();
          }}
        >
          Logout
        </div>
      ),
    },
  ];
  const AdminLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate('/admin-login-page');
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <LayoutHeader
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        background: colorBgContainer,
      }}
    >
      <Flex justify='space-between' align='center' style={{ width: '100%' }}>
        <Flex align='center' gap='small'>
          {navBarCollapseAction()}
          <Typography.Title level={5} style={{ margin: 0 }}>
            {(route?.handle as any)?.title}
          </Typography.Title>
        </Flex>
        <Space direction='vertical' size={16} wrap style={{ marginRight: 16 }}>
          <Dropdown menu={{ items }} placement='bottomRight'>
            <Avatar
              className='image'
              size='small'
              src={
                <img
                  src='https://images.pexels.com/photos/13195312/pexels-photo-13195312.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                  alt='profile'
                  style={{ width: 32, height: 32 }}
                />
              }
              alt='profile'
            />
          </Dropdown>
        </Space>
      </Flex>
    </LayoutHeader>
  );
}
