const apiRoutes = {
  dashboardData: '/dashboard',
  serviceRequestGraph: '/graph',
  user: {
    me: '/me',
  },
  serviceRequests: {
    getList: '/service-requests',
  },
  seeker: {
    getList: '/seekers',
  },
  provider: {
    getList: '/providers',
  },
  withdrawal: {
    getList: '/withdrawals',
  },
};

export default apiRoutes;
