import axios, { AxiosRequestConfig } from 'axios';
import './api.header';

const api = {
  get: <T>(url: string, params?: object) =>
    axios.get<T>(url, {
      ...params,
    }),
  post: <T>(url: string, data: any, config?: AxiosRequestConfig) =>
    axios.post<T>(url, data, config),

  patch: <T>(url: string, data: any) => axios.patch<T>(url, data),
  put: <T>(url: string, data: any) => axios.put<T>(url, data),
  delete: <T>(url: string) => axios.delete<T>(url),
  getCancelToken: () => axios.CancelToken.source(),
};

export default api;
