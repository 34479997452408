import apiRoutes from './api.routes';
import api from './api.service';
import type { QueryClient } from '@tanstack/react-query';
import type {
  IDashboardDataResponse,
  IServiceRequestGraphResponse,
} from '../Components/Dashboard/dashboard.interface';
import omitBy from 'lodash/omitBy';

// QUERY KEYS
const DASHBOARD_STATS = ['dashboard', 'data'];
const SERVICE_REQUEST_GRAPH = ['service-request', 'graph'];

export const fetchDashbaordStats = () => ({
  queryKey: DASHBOARD_STATS,
  queryFn: async () =>
    await api
      .get<IDashboardDataResponse>(apiRoutes.dashboardData)
      .then(({ data }) => data),
});

export const fetchServiceRequestGraph = () => ({
  queryKey: SERVICE_REQUEST_GRAPH,
  queryFn: async () =>
    await api
      .get<IServiceRequestGraphResponse>(apiRoutes.serviceRequestGraph)
      .then(({ data }) => data),
});

export const dashboardPageLoader = async (queryClient: QueryClient) => {
  const dashboardData = fetchDashbaordStats();
  const serviceRequestGraph = fetchServiceRequestGraph();

  let data = omitBy(
    {
      dashboardData: queryClient?.getQueryData<IDashboardDataResponse>(
        dashboardData.queryKey
      ),
      serviceRequestGraph:
        queryClient?.getQueryData<IServiceRequestGraphResponse>(
          serviceRequestGraph.queryKey
        ),
    },
    (val) => val === undefined
  );

  if (!data) {
    const [dashboard, serviceRequest] = await Promise.all([
      queryClient?.fetchQuery<IDashboardDataResponse>(dashboardData),
      queryClient?.fetchQuery<IServiceRequestGraphResponse>(
        serviceRequestGraph
      ),
    ]);
    data = {
      dashboardData: dashboard,
      serviceRequestGraph: serviceRequest,
    };
  }
  return data;
};
