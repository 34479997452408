import apiRoutes from './api.routes';
import api from './api.service';

const ME = ['me'];

export const fetchMe = () => ({
  queryKey: ME,
  queryFn: async () =>
    await api.get(apiRoutes.user.me).then(({ data }) => data),
  staleTime: Infinity,
  retryOnMount: true,
});
