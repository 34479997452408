import { Flex } from 'antd/lib';
import Loader from './Loader';

export default function PageLoader() {
  return (
    <Flex
      style={{
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9999999,
        background: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(6px)',
      }}
      align='center'
      justify='center'
    >
      <Loader />
    </Flex>
  );
}
