import { Outlet, createBrowserRouter } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import ProtoctedLayout from '../Layouts/ProtoctedLayout';
import Result from 'antd/lib/result';
import Button from 'antd/lib/button';
import type { QueryClient } from '@tanstack/react-query';
import { fetchMe } from '../apis/user.api';
import PageLoader from '../Components/Loader/PageLoader';
import { serviceRequesterPageLoader } from '../apis/serviceRequests.api';
import { seekerPageLoader } from '../apis/seeker.api';
import { providerPageLoader } from '../apis/provider.api';
import { dashboardPageLoader } from '../apis/stats.api';
import { withDrawalsPageLoader } from '../apis/withdrawl.api';

const LandingPage = lazy(
  () => import('../Components/LandingPage/wholeLandingPage/landingpage')
);
const LoginPage = lazy(() => import('../Components/loginPage/Login'));
const Home = lazy(() => import('../Pages/Home'));
const ProviderManagement = lazy(() => import('../Pages/Provider'));
const ProviderInfo = lazy(() => import('../Components/ProviderInfo/info'));
const Booking = lazy(() => import('../Pages/Booking'));
const Seeker = lazy(() => import('../Pages/Seeker'));
const Withdrawals = lazy(() => import('../Pages/Withdrawals'));

export const routes = (queryClient: QueryClient) =>
  createBrowserRouter([
    {
      path: '',
      element: suspenseRoute(<LandingPage />),
    },
    {
      path: 'login',
      element: suspenseRoute(<LoginPage />),
    },
    {
      path: '',
      element: <ProtoctedLayout>{<Outlet />}</ProtoctedLayout>,
      loader: async () => {
        const query = fetchMe();
        return (
          queryClient?.getQueryData(query.queryKey) ??
          (await queryClient?.fetchQuery(query))
        );
      },
      children: [
        {
          path: 'dashboard',
          element: suspenseRoute(<Home />),
          handle: { title: 'Dashboard' },
          index: true,
          loader: async () => await dashboardPageLoader(queryClient),
        },
        {
          path: 'providers',
          element: <Outlet />,
          handle: { title: 'Provider Management' },
          children: [
            {
              path: '',
              element: suspenseRoute(<ProviderManagement />),
              handle: { title: 'Provider Management' },
              loader: async () => await providerPageLoader(queryClient),
              index: true,
            },
            {
              path: 'provider-info',
              element: suspenseRoute(<ProviderInfo />),
              handle: { title: 'Provider Info' },
            },
          ],
        },
        {
          path: 'bookings',
          element: <Outlet />,
          handle: { title: 'Service Bookings' },
          children: [
            {
              path: '',
              element: suspenseRoute(<Booking />),
              handle: { title: 'Service Bookings' },
              index: true,
              loader: async () => await serviceRequesterPageLoader(queryClient),
            },
          ],
        },
        {
          path: 'seekers',
          element: <Outlet />,
          handle: { title: 'Seeker Management' },
          children: [
            {
              path: '',
              element: suspenseRoute(<Seeker />),
              handle: { title: 'Seeker Management' },
              index: true,
              loader: async () => await seekerPageLoader(queryClient),
            },
          ],
        },
        {
          path: 'withdrawals',
          element: <Outlet />,
          handle: { title: 'Withdrawals' },
          children: [
            {
              path: '',
              element: suspenseRoute(<Withdrawals />),
              handle: { title: 'Withdrawals' },
              index: true,
              loader: async () => await withDrawalsPageLoader(queryClient),
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: (
        <Result
          status='404'
          title='404'
          subTitle='Sorry, the page you visited does not exist.'
          extra={
            <Button type='primary' href='/'>
              Back Home
            </Button>
          }
        />
      ),
    },
  ]);

export function suspenseRoute(component: JSX.Element) {
  return <Suspense fallback={<PageLoader />}>{component}</Suspense>;
}
